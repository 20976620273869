.advantages{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 5vw -100px 5vw;
  height: 100vh;
  @include mq($until: xl){
    margin: 200px 0 100px 0;
    height: auto;
  }
  @include mq($until: lg){
    margin-top: 150px;
  }
  @include mq($until: sm){
    margin-top: 100px;
  }
  &__icons{
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include mq($until: sm){
      justify-content: space-around;
      flex-wrap: wrap;
    }
  }
}
.advantage{
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mq($until: lg){
    max-width: 120px;
  }
  @include mq($until: sm){
    margin-bottom: 30px;
  }
  &:hover{
    .advantage__icon{
      transform: scale(1);
    }
    .advantage__txt{
      color: $color-main;
    }
  }

  &__square{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-bg-extra;
    border-radius: 8px;
    height: 180px;
    width: 180px;
    @include mq($until: lg){
      height: 120px;
      width: 120px;
    }
  }
  &__icon{
    transform: scale(.9);
    transition: transform .3s ease-in-out;
    @include mq($until: lg){
      max-width: 100px;
    }
  }
  &__txt{
    font-variant: small-caps;
    font-weight: $fw-medium;
    text-align: center;
    transition: color .3s ease-in-out;
    margin-top: 30px;
    @include mq($until: lg){
      margin-top: 15px;
      font-size: rem(14px);
    }
  }
}

.zdrojowa{
  position: relative;
  display: flex;
  flex-direction: column;
  &:hover .zdrojowa__header{
    color: $color-main;
  }
  &__content{
    position: absolute;
    bottom: 0;
    background-color: $color-bg-extra;
    width: 100%;
    padding: 20px;
    @include mq($until: md){
      position: static;
    }
  }
  &__header{
    margin: 0;
    color: #fff;
    font-weight: 600;
    text-align: center;
    transition: color .3s ease-in-out;
    @include mq($until: lg){
      font-size: 2vw;
    }
    @include mq($until: xs){
      font-size: 4vw;
    }
    &--sub{
      font-variant: small-caps;
      text-transform: none;
      font-weight: 400;
      margin-top: 10px;
      font-size: rem(26px);
      color: #ffffff !important;
      @include mq($until: lg){
        font-size: 1.8vw;
      }
      @include mq($until: xs){
        font-size: 3.6vw;
      }
    }
  }
}