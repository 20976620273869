.svg-icon{
  transition: fill .3s ease-in-out;
  @include mq($until: xs){
    max-width: 25px;
  }
}
.contact-data{
  padding-top: 5vw;
  padding-bottom: 5vw;
  background-color: $color-main;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 30px;

  @include mq($until: md){
    grid-template-columns: 1fr;
  }

  &--homepage{
    @include mq($until: lg){
      grid-template-columns: repeat(3,1fr);
    }
    @include mq($until: md){
      grid-template-columns: repeat(2,1fr);  
    }
    @include mq($until: sm){
      grid-template-columns: 1fr;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }
  }

  &__section{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    p{
      margin-bottom: 0;
      color: #fff;
    }
    @include mq($until: sm){
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &--name{
      display: block;
      p{
        color: #fff;
        font-size: rem(20px);
        font-weight: $fw-semi-bold;
      }
    }
    &--social {
      @include mq($until: sm){
       flex-direction: row;
      }
    }
    &--social .svg-icon{
      max-width: unset;
      margin-right: 5px;
      fill: #fff;
      @include mq($until: xs){
          max-width: 35px;
      }
    }
    &--social a:hover .svg-icon{
        fill: $color-extra;
    }
  }

  &__icon{
    margin-right: 15px;
    @include mq($until: sm){
      margin-right: 0;
      padding-bottom: 8px;
    }
    svg{
      fill: #fff;
    }
  }
  &__content{
    &--phones, &--emails{
      display: flex;
      flex-direction: column;
      a{
        color: #fff;
      }
    }
  }
}

.contact-data-white{

  padding-top: 5vw;
  padding-bottom: 5vw;
  background-color: unset;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 30px;

  @include mq($until: md){
    grid-template-columns: 1fr;
  }

  &--homepage{
    @include mq($until: lg){
      grid-template-columns: repeat(3,1fr);
    }
    @include mq($until: md){
      grid-template-columns: repeat(2,1fr);  
    }
    @include mq($until: sm){
      grid-template-columns: 1fr;
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }
  }

  &__section{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    p{
      margin-bottom: 0;
      color: $color-main;
    }
    @include mq($until: sm){
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &--name{
      display: block;
      p{
        color: $color-main;
        font-size: rem(20px);
        font-weight: $fw-semi-bold;
      }
    }
    &--social {
      @include mq($until: sm){
       flex-direction: row;
      }
    }
    &--social .svg-icon{
      max-width: unset;
      margin-right: 5px;
      fill: $color-main;
      @include mq($until: xs){
          max-width: 35px;
      }
    }
    &--social a:hover .svg-icon{
        fill: $color-extra;
    }
  }

  &__icon{
    margin-right: 15px;
    @include mq($until: sm){
      margin-right: 0;
      padding-bottom: 8px;
    }
    svg{
      fill: $color-main;
    }
  }
  &__content{
    &--phones, &--emails{
      display: flex;
      flex-direction: column;
      a{
        color: $color-main;
      }
    }
  }
}
