.icon-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 70vh;
    position: relative;
    padding-bottom: 8vw;
    justify-content: center;
    &__heading{
        padding-top: 8vw;
        margin-bottom: 5vw;
        z-index: 1;
        h2{
            color: $icons-bg;
        }
    }
    &__wrapper{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        align-items: flex-start;
        z-index: 1;
    } 
    .color{
       background:  $color-main ;
    }
    &__iconItem{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin: 34px;
        width: 130px;
        img{
            height: 100px;
            width: 100px;
            width: auto;
            margin-bottom: 21px;
            -webkit-filter: opacity(1) drop-shadow(0 0 0 $color-icons);
            filter: opacity(.8) drop-shadow(0 0 0 $icons-bg) contrast(250%) contrast(190%) brightness(110%);
            @include mq($until: md) {
                height: 8vw;
                
            }
            @include mq($until: sm) {
                height: 10vw;
                
            }
            @include mq($until: xs) {
                height: 55px;
                
            }
        }
        p{
            width: 100px;
            color: $icons-bg;
        }
    }
    .icon-image{
        min-height: 80vh;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        padding-top: 100px;
        padding-bottom: 100px;
        position: absolute;
        z-index: 0;
        @include mq($until: md) {
            min-height: 60vh;
        }
        @include mq($until: sm) {
            min-height: 400px;
            padding-top: 50px;
            padding-bottom: 50px;
            align-items: flex-start;
        }
        &--textbox{
            @include mq($until: sm) {
                min-height: 300px;
            }
        }
        &__bg{
            background-blend-mode: overlay;

            position: absolute;
            background-color: $color-main;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            &--paralaxa{
                background-attachment: fixed;
            }
            &--textbox{
                @include mq($until: sm) {
                    height: 300px;
                }
            }
        }
        &__box-wrapper{
            width: 100%;
            display: flex;
            align-items: center;
            position: relative;
            &--left{
                justify-content: flex-start;
            }
            &--right{
                justify-content: flex-end;
            }
            @include mq($until: sm) {
                margin-top: 150px;
            }
        }
        &__header-box{
            display: flex;
            flex-direction: column;
            grid-gap: 20px;
            padding: 40px;
            max-width: 600px;
            @include mq($until: md) {
                max-width: 400px;
                padding: 30px;
            }
            @include mq($until: sm) {
                max-width: 100%;
                padding: 20px;
                position: relative;
                &:before{
                    position: absolute;
                    z-index: -1;
                    content: "";
                    top: 0;
                    left:0;
                    height: 100%;
                    width: 100%;
                    -webkit-box-shadow: 1px 4px 7px -4px rgba(0,0,0,0.7);
                    -moz-box-shadow: 1px 4px 7px -4px rgba(0,0,0,0.7);
                    box-shadow: 1px 4px 7px -4px rgba(0,0,0,0.7);
                }
    
            }
            &--border {
                background-color: $color-box;
            }
        }
    }  
}

