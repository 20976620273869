.header{
    top: 0;
    width: 100%;
    z-index: 9;
    position: relative;
    &__nav{
        display: flex;
        align-items: center;
        position: relative;
        .socicon, svg{
            margin-left: 1vw;
            fill: #fff;
            opacity: .4;
            transition: .4s ease-in-out;
            &:hover{
                opacity: 1;
            }
            @include mq($until: md){
                margin-left: 0;
               margin-top: 55px;
                min-width: 55px;
            }
        }
    }
    &--subsites{
        background-color: $color-main;
        position: relative;

    }
    &--subpage{
        background-color: $color-main-transparent;
        position: relative;
    }
    &--main{
        position: absolute;
        background-color:unset;
        padding-top: 13px;
    }
    &--apartment{
        background-color: $color-main-transparent;
        position: absolute;
 
        .header__logo {
            max-width: 160px;
            @include mq($until: lg){
                max-width:130px;
            }
            @include mq($until: md){
                max-width:80px;
            }
        }
    }


    &--default{
        position: relative;
        background-color:none;
    }
    // @include mq($until: md) {

    //     padding-top: 3vw;
    // }
    &--fixed {
        position: fixed;
        transition: background-color .3s ease-in-out;
        &.sticky{
          background-color: $color-main;
        }
    }
&__wrapper{
    display: flex;

    justify-content: space-between;
    align-items: center;
}
    // &--absolute {
    //     position: absolute;
    // }

    &__logo{
      display: flex;
      align-items: center;
    }

    &__menuList{
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        margin: 0;
    }
    &__menuItem{
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 25px;
        padding: 0;
        &::before{
            display: none;
        }
    }

    .contact-data__section.contact-data__section--social {
        position: absolute;
        top: 130px;
        right: 0;
        
        a {
            svg {
                // fill: black;
                opacity: 1;
            }
        }
    }

    &__menuLink{
        color: #fff;
        font-size: rem(18px);
        font-weight: 500;
        padding-top: 13px;
        padding-bottom: 13px;
        transition: all .4s ease-in-out;
        @include mq($until: xl) {
            font-size: 1.1vw;
        }
        @include mq($until: lg) {
            font-size: 1vw;
        }
        @include mq($until: md) {
            border-bottom: 1px solid rgba(255, 255, 255, 0);
            transition: all .3s;
        }

        &:active, &--active, &--open{
            // color: $color-main;
            color:$package-card-hover;
            opacity: .6;
        }

        &:hover {
            color:$package-card-hover;
            opacity: .6;
            @include mq($until: md) {
                color: white;
                border-bottom: 1px solid rgba(255, 255, 255, 1);
            }
        }
    }
 
    &__logo {
        max-width: 210px;
        z-index: 50;
        padding-top: 13px;
        padding-bottom: 13px;
        @include mq($until: xxl){
            max-width:11vw;
        }
        @include mq($until: lg){
            max-width:18vw;
        }
        @include mq($until: md){
            max-width:160px;
        }
        @include mq($until: sm){
            max-width:130px;
        }

    }
    &__atrakcja{  

            background-color: $color-main-transparent;
            position: absolute;
            width: 100%;
    }

    @include mq($until: md) {
        .contact-data__section.contact-data__section--social {
            position: static;
        }
    }
}


