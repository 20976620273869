.news{
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    grid-column-gap: 50px;
    grid-row-gap: 25px;
    margin-top: 50px;
    margin-bottom: 50px;
    @include mq($until: md) {
        grid-template-columns: 1fr;
    }
    &__left-column{
        @include mq($until: md) {
            order: 2;
        }
    }
    &__right-column{
        @include mq($until: md) {
            order: 1;
        }
    }

    &__date{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 30px;
        img{
            margin-right: 10px;
        }
        p{
            font-style: italic;
            font-weight: $fw-bold;
        }
    }
    &__category{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 30px;
        p{
            font-weight: $fw-bold;
            margin-right: 5px;
            color: $color-main;
        }
    }
    &__categories{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        a, span{
            color: $color-font-dark;
            &::after{
                content: '|';
                margin-left: 10px;
                margin-right: 10px;
            }
            &:last-of-type::after{
                display: none;
            }
        }
    }
    &__image{
        margin-bottom: 30px;
        width: 100%;
        img{
            width: 100%;
        }
    }
    .gallery{
        margin-top: 60px;
        margin-bottom: 30px;
    }
}