/*------------------------------------*\
    # Type
\*------------------------------------*/

// Make type normal across browsers
*, html, body {
  font-size: $base-font-size;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  @include mq($until: xl) {
    font-size: 15px;
  }
  @include mq($until: md) {
    font-size: 14px;
  }
  @include mq($until: sm) {
    font-size: 13px;
  }
  @include mq($until: xs) {
    font-size: 12px;
  }
}

// Headers
h1, h2, h3, h4, h5, h6 {
  margin-top: $header-margin * 1.5;
  color: $header-color;
  line-height: $header-line-height;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }
}

h1 {
  font-size: rem($header-size);
  margin-bottom: $header-margin;
  font-family: 'Merriweather Montserrat';
  font-weight: 700;
}
.subtitle{
  font-family: 'Montserrat';
}
h2 {
  font-size: rem($header-size / 1.3);
  margin-bottom: $header-margin / 1.3;
}

h3 {
  font-size: rem($header-size / 1.5);
  margin-bottom: $header-margin / 1.5;
}

h4 {
  font-size: rem($header-size / 1.7);
  margin-bottom: $header-margin / 1.7;
}

h5 {
  font-size: rem($header-size / 1.8);
  margin-bottom: $header-margin / 1.8;
}

h6 {
  font-size: rem($header-size / 2);
  margin-bottom: $header-margin / 2;
}

// Paragraphs
p, div, span {
  // font-size: rem($paragraph-size);
  font-size: calc(16px + 1 * ((100vw - 320px) / 680));
  line-height: $paragraph-line-height;
  color: $paragraph-color;
}

p {
  margin-bottom: $paragraph-margin;

  &:last-of-type {
    margin-bottom: 0;
  }
}

// Links
a {
  color: $link-color;
  transition: color 0.3s ease-in-out;
  text-decoration: none;

  &:hover {
    color: darken($link-color, 20%);
    text-decoration: none;
  }
}


// Lists
ul,
ol {
  margin: $paragraph-margin 0 $paragraph-margin 20px;

  li {
    margin-top: 10px;
    line-height: $paragraph-line-height;
    color: $paragraph-color;
  }

  ul,
  ol {
    margin-top: 0;
  }
}

ul {
  margin-left: 0;

  li {
    list-style: none outside none;
    padding-left: 5px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    &::before {
      content: '';
      background-color: $color-main;
      width: rem(5px);
      height: rem(5px);
      margin-right: rem(10px);
      display: inline-block;
      transform: rotate(45deg);
      flex-shrink: 0;
      margin-top: rem(9px);
    }
  }

  ul {
    li {
      list-style: disc;
    }
  }
}

ol {
  li {
    list-style: decimal;
  }
}


// hr
hr {
  height: 1px;
  margin: $hr-margin 0;
  border: none;
  background-color: $border-color;
  width: 100%;
}


// Blockquote
blockquote {
  margin: $blockquote-margin 0;
  font-size: rem($blockquote-size);
  line-height: $blockquote-line-height;
  color: $blockquote-color;
  text-align: center;
  font-style: italic;
}


// Tables
table {
  width: 100%;
  margin: $blockquote-margin 0;
  border-collapse: collapse;
  overflow-x: scroll;
  display: block;
}

table, td, th {
  border: 1px solid $border-color;
  text-align: left;
}

th {
  font-weight: $font-weight-bold;
}

th, td {
  padding: rem(15px);
}


// Figure
figure {
  margin: $blockquote-margin 0;

  img {
    margin: 0;
  }
}

figcaption {
  margin-top: 10px;
  color: lighten($paragraph-color, 10%);
  font-size: rem($paragraph-margin * 0.8);
}


// Code
pre {
  display: block;
  margin: 0 0 40px 0;
  padding: 20px;
  background-color: lighten($border-color, 12%);
  border: 1px solid $border-color;
  overflow: auto;
  border-radius: 3px;

  code {
    position: static;
    padding: 0;
    border: none;
    line-height: 1.5em;
  }
}

code {
  position: relative;
  top: -0.2em;
  padding: 3px;
  font-family: Courier New, Courier, monospace;
  font-size: rem($paragraph-size / 1.2);
  color: darken($border-color, 70%);
  line-height: 1em;
  pointer-events: none;
  border: 1px solid $border-color;
  border-radius: 3px;
}


// Misc Styles
em,
i,
.italic {
  font-style: italic;
}

strong,
b,
.bold {
  font-weight: $font-weight-bold;
}

img {
  max-width: 100%;
}