.news-list{
    display: grid;
    grid-gap: 30px;
    row-gap: 60px;
    margin-top: 100px;
    margin-bottom: 100px;
    grid-template-columns: repeat(3, 1fr);
    @include mq($until: md) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include mq($until: sm) {
        grid-template-columns: repeat(1, 1fr);
    }

    &--promo{
        margin-bottom: 30px;
    }
    &__header{
        margin-bottom: 30px;
        &--grid{
            grid-column-start: span 4;
            margin-bottom: 0;
        }
    }
}