.eu-banner{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq($until: sm) {
        flex-wrap: wrap;
    }
    img{
        max-width: 25vw;
        @include mq($until: md) {
            max-width: unset;
            min-width: 150px;
        }
    }
}
.line{
    height: 1px;
    width: 100%;
    background-color: $color-main;
}
.footer-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding-bottom: 15px;

    @include mq($until: md) {
        flex-direction: column;
        justify-content: center;

        & > * {
            margin-bottom: 10px;
        }
    }

    a {
        display: flex;
        align-items: center;

        img {
            margin-left: 10px;
        }
    }
}
.copyright{
    display: flex;
    align-items: center;
}