.arrow-slide{
  cursor: pointer;
  position: absolute;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  bottom: 10%;
  z-index: 2;
  border: none;
  background-color: $color-extra;
  font-size: 1.5em;
  transition:  background-color .3s ease-in-out;
  margin-bottom: 2vw;
  &:hover{
    background-color:$color-main;
  }
  @include mq($until: md){
    width: 44px;
    height:44px;  
  }
  @include mq($until: xs){
    width: 34px;
    height:34px;  
  }

  &--next{
    background-image: url('../../images/button-arrow-white.png');
    right: 8vw;
    background-repeat: no-repeat;
    background-position:center;
    background-size: 13px;

    @include mq($until: xs){
      background-size: 8px;
      right: 5vw;
    }
  }
  &--prev{
    background-image: url('../../images/button-arrow-white.png');
    transform: rotate(180deg);
    right:13vw;
    background-repeat: no-repeat;
    background-position:center;
    background-size: 13px;

    @include mq($until: xl){
      right:15vw;
      
    }
    @include mq($until: lg){
      right:17vw;
    }
    @include mq($until: xs){
      background-size: 8px;
    }
  }
}
