.photolink-block{
    background-color:$color-bg;
    padding-top: 2vw;
    padding-bottom: 5vw;
    @include mq($until: md){
        padding-top: 8vw;
    }
    @include mq($until: sm){
        padding-top: 15vw;
    }
    @include mq($until: xs){
        padding-top:130px;
    }

    &__heading{
        width: 100%;
        text-align: center;
        padding-top: 5vw;
        padding-bottom: 3vw;
        h2{
            font-size: 1.8vw;
            font-weight: normal;
            color: grey;
            @include mq($until: lg){
                font-size:2.5vw;
            }
            @include mq($until: sm){
                font-size:3vw;
            }
            @include mq($until: xs){
                font-size:4vw;
            }
        }
    }
    &__arangement{
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(23%, 1fr) );
         grid-row-gap: 3vw;
         grid-column-gap: 1.3vw;
         @include mq($until: lg){
           padding-bottom: 5vw;
           grid-template-columns: repeat(auto-fit, minmax(34%, 1fr));
           grid-row-gap: 3.4vw;
           grid-column-gap: 3.4vw;
         }
         @include mq($until: md){
            grid-template-columns: 1fr;
         }
    }
    &__wrapper{
        display: flex;
        flex-direction: column;

        @include mq($until: md){
            flex-direction: row;
           justify-content: center;
        }
        @include mq($until: xs){
            flex-direction: column;
           justify-content: center;
        }

    }

    &__item{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: all .4s ease-in-out;
        &:hover {
        }
        @include mq($until: lg){
            min-height: 30vw;
        }
    }
    &__photoItem{  
        height:11vw;
        width: 100vw;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin: 0 auto;
        border-radius: 8px 8px 0 0;

        @include mq($until: lg){
            height: 21vw;
        }
        @include mq($until: md){
            height: 21vw;
            width: 45vw;
            border-radius: 8px 0 0 8px ;
            min-height: 30vw;
        }
        @include mq($until: md){
            width: 50vw;
        }
        @include mq($until: xs){
            width: 100%;
            height: 50vw;
            border-radius: 8px  8px 0 0;
            min-height: unset;
        }
    }
    &__textItem{
        margin-top: 0;
        padding: 1.3vw 1vw;
        background-color: #fff;
        height: 5.5vw;
        text-align: center;

        border-radius:  0 0 8px 8px;
        @include mq($until: lg){
            padding: 2vw 2vw;
        }
        @include mq($until: md){
            padding: 5vw 5vw;
            height: 21vw;
            width: 55vw;
            text-align: left;
            border-radius: 8px 0 0 8px ;
            min-height: 30vw;
        }
        @include mq($until: sm){
            width: 50vw;
        }
        @include mq($until: xs){
            text-align: center;
            width: 100%;
            border-radius:  0 0 8px 8px;
            padding: 5vw 3vw 3vw 3vw;
            height: unset;
        }
    }
}