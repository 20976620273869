.subsite {
  margin-bottom: 10px;
  // padding-top: 2vw;
  &__header {
    width: 100%;
    height: 30vw;
    display: flex;
    align-items: flex-end;
    margin-bottom: 40px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center;

    @include mq($until: lg) {
      height: 34vw;
    }

    @include mq($until: md) {
      height: 40vw;
    }

    @include mq($until: sm) {
      height: 55vw;
    }
    @include mq($until: xs) {
      height: 65vw;
    }

    div {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
      display: flex;
      align-content: center;

      .subheading {
        margin: 0;
        width: 100%;
        font-size: 3vw;
        bottom: 3vw;

        line-height: 3;
        height: 100%;
        padding-left: 0;

        -webkit-text-stroke-width: 1.3px;
        -webkit-text-stroke-color: #3f300f69;


        background-image: url("/storage/app/media/fotki%20bg%20glowna/gold.jpg");
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}