.package{
  &__price{
    font-size: rem(32px);
    margin: 0 0 1vw 0;
    display: block;
    font-variant: small-caps;
    span{
      font-weight: $fw-semi-bold;
      color: $color-extra;
      font-size: inherit;
    }
  }
  &__price{
    color: $color-extra; 
  }
  &__content{
    position: relative; 
    z-index: 7;
  }
}

.header{
  &__photo{
    width: 100vw;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    &--catalog{
      height: 40vw;
      @include mq($until: md){
        height: 55vw;
      }
      @include mq($until: sm){
        height: 80vw;
      }
      @include mq($until: sm){
        height: 100vw;
      }
    }
  }

  &__title{
    width: 100%;
    color: #fff;
    font-size: 3vw;
    display: flex;
    align-items: flex-end;
    line-height: 1;
    padding-bottom: 3vw;
    height: 100%;
    padding-left: 0;
    text-shadow: 4px 4px 55px rgb(0, 0, 0);
  }
}