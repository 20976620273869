
.top-image{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: $color-site-bg;
    &__text{
        p{
            font-size: rem(18px);
            color:#fff;
        }
    }
    
    &__heading{
    //   color: #ebd07a;
        text-align: left;
        font-size: 3vw;
        line-height: 1;
        // text-shadow: 4px 4px 34px rgb(0, 0, 1);
    
        // text-shadow: 4px 4px 55px rgb(0, 0, 1);

        // -webkit-text-stroke-width: 0.5px;
        // -webkit-text-stroke-color: #f1c972;

        // background: -webkit-linear-gradient( 50deg, #c9a672 10%, #a57434 20%, #e6bd81,  #926832 80%, #f5cf96 20%,);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;


        background-image: url("/storage/app/media/fotki%20bg%20glowna/gold.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        // -webkit-font-smoothing: antialiased;


        @include mq($until: xl) {
            font-size: 4.4vw;
        }
        @include mq($until: lg) {
            font-size: 5vw;
        }
        @include mq($until: md) {
            font-size: 6.5vw;
        }
        @include mq($until: md) {
            font-size: 6.5vw;
        }
        @include mq($until: sm) {
            font-size: rem(55px);
        }
        // @include mq($until: xs) {
        //     font-size: rem(34px);
    
        // }
    }
    &__box-wrapper{
        width: 100%;
        height: 100%;
        position: relative;
    }
    &__header-box{
        display: grid;
        grid-gap: 20px;
        position: absolute;
        top: 55%;
        transform: translateY(-50%);
        // background-color: #fff;
        padding: 40px;
        max-width: 600px;
        @include mq($until: md) {
            padding: 30px;
        }
        @include mq($until: sm) {
            padding: 20px;
        }
        &--left{
            left: 0;
        }
        &--right{
            right: 0;
            max-width: 60%;
            @include mq($until: lg) {
                max-width: 65%;
            }
            @include mq($until: xs) {
                max-width:80%;
            }
        }
        &--border {
            background-color:$color-box;
            opacity: .9;
        }
    }
}