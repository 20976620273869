.call-section{
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 70vh;
    position: relative;
    justify-content: center;
    @include mq($until: sm){
        // justify-content: flex-start;
        // min-height: 50vh;
    }
    &__heading{
        margin-bottom: 1vw;
        // @include mq($until: sm){
        //     padding-top: 13vw;
        // }
        z-index: 5;
        h2{
            color: $color-main;
            font-family: $font-family-header;
            font-size: 1.6vw;
            @include mq($until: md){
                font-size: 3vw;
              }
            @include mq($until: sm){
                font-size: rem(32px);
                text-align: center;
                max-width: 80%;
                margin: 0 auto;
                padding-bottom: 21px;
              }
        }
    }
    &__reservation{
        z-index: 5;
        .hotresChooser{
            position: absolute;
            left: 0; 
            right: 0; 
            margin-left: auto; 
            margin-right: auto; 
            max-width:50vw;
            @include mq($until: md){
                max-width:90vw;
              }
        }
    }
    &__wrapper{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        align-items: flex-start;
        z-index: 5;
        padding-bottom: 3vw;
        @include mq($until: sm){
            padding-bottom: 8vw;
          }
        p{
            max-width: 70%;
            display: flex;
            text-align: center;
           margin: auto;
        }
    }
    &__mask{
        position: absolute;
        z-index: 1;
        width: 100%;
       height: 100%;
       box-shadow: inset 0px 0px 45px 80px $color-bg;
       border-radius: 30% 40% 30% 40%;
       @include mq($until: lg) {
        width: 100%;
       }
    }
    .action-image{
        min-height: 80vh;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        padding-top: 100px;
        padding-bottom: 100px;
        position: absolute;
        z-index: 0;
        @include mq($until: md) {
            min-height: 60vh;
        }
        @include mq($until: sm) {
            min-height: 400px;
            padding-top: 50px;
            padding-bottom: 50px;
            align-items: flex-start;
        }
        &--textbox{
            @include mq($until: sm) {
                min-height: 300px;
            }
        }
        &__bg{
            opacity: .5;
            position: absolute;
            border-radius: 30% 40% 30% 40%;
            top: 0;
            // left: 0;
            height: 99%;
            width: 99%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            &--paralaxa{
                background-attachment: fixed;
            }
            &--textbox{
                @include mq($until: sm) {
                    height: 300px;
                }
            }
            @include mq($until: lg) {
                width: 99%;
         }
        }
        &__box-wrapper{
            width: 100%;
            display: flex;
            align-items: center;
            position: relative;
            &--left{
                justify-content: flex-start;
            }
            &--right{
                justify-content: flex-end;
            }
            @include mq($until: sm) {
                margin-top: 150px;
            }
        }
        &__header-box{
            display: flex;
            flex-direction: column;
            grid-gap: 20px;
            padding: 40px;
            max-width: 600px;
            @include mq($until: md) {
                max-width: 400px;
                padding: 30px;
            }
            @include mq($until: sm) {
                max-width: 100%;
                padding: 20px;
                position: relative;
                &:before{
                    position: absolute;
                    z-index: -1;
                    content: "";
                    top: 0;
                    left:0;
                    height: 100%;
                    width: 100%;
                    -webkit-box-shadow: 1px 4px 7px -4px rgba(0,0,0,0.7);
                    -moz-box-shadow: 1px 4px 7px -4px rgba(0,0,0,0.7);
                    box-shadow: 1px 4px 7px -4px rgba(0,0,0,0.7);
                }
            }
            &--border {
                background-color: $color-box;
            }
        }
    }  
}

