.full-image{
    min-height: 80vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
    @include mq($until: md) {
        min-height: 60vh;
    }
    @include mq($until: sm) {
        min-height: 400px;
        padding-top: 50px;
        padding-bottom: 50px;
        align-items: flex-start;
    }
    &--textbox{
        @include mq($until: sm) {
            min-height: 300px;
        }
    }
    &__bg{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        &--paralaxa{
            background-attachment: fixed;
        }
        &--textbox{
            @include mq($until: sm) {
                height: 300px;
            }
        }
    }
    &__box-wrapper{
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        &--left{
            justify-content: flex-start;
        }
        &--right{
            justify-content: flex-end;
        }
        @include mq($until: sm) {
            margin-top: 150px;
        }
    }
    &__header-box{
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        padding: 40px;
        max-width: 600px;
        @include mq($until: md) {
            max-width: 400px;
            padding: 30px;
        }
        @include mq($until: sm) {
            max-width: 100%;
            padding: 20px;
            position: relative;
            &:before{
                position: absolute;
                z-index: -1;
                content: "";
                top: 0;
                left:0;
                height: 100%;
                width: 100%;
                -webkit-box-shadow: 1px 4px 7px -4px rgba(0,0,0,0.7);
                -moz-box-shadow: 1px 4px 7px -4px rgba(0,0,0,0.7);
                box-shadow: 1px 4px 7px -4px rgba(0,0,0,0.7);
            }

        }
        &--border {
            background-color: $color-box;
        }
    }
}